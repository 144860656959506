






































import Repository from "../Repository"

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Scheduler extends Vue {

  data = {}
  error =  String()


  mounted() {

    console.log("Hi")

    Repository.get(`scheduler/event`)
      .then(res => (this.data = res.data))
      .catch(error => (this.error = error))

  }
    


}
