


















































































import Repository from "../Repository"

import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class Wiki extends Vue {

  data = {}
  error =  String()

  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  }

  @Watch('$route')
  onRouteChanged() { this.fetchData() }

  // methods
  fetchData() {
    this.error = ""
    this.data = {}


    // replace `getPost` with your data fetching util / API wrapper
    Repository.get(`health`)
      .then(res => (this.data = res.data))
      .catch(error => (this.error = error))

  }


}
